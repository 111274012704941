
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Appointments</h5>
      <div class="row mb-5">
        <div class="col-12">
          <div class="card overflow-hidden">
              <div class="card-body bg-marketplace d-flex">
                <div class="flex-grow-1">
                    <h4 class="fs-18 lh-base fw-bold mb-0">Can't leave home or work? Don't worry. <span class="text-success">We come to you!</span></h4>
                    <p class="mb-0 mt-2 pt-1 text-muted">As an authorized repair provider for Apple®, Samsung®, and various other brands, our certified experts specialize in swiftly restoring your damaged device to full functionality.</p>
                    <div class="d-flex gap-3 mt-4">
                    <a class="btn btn-primary" target="_blank" :href="absoluteUrl('/repairs')">Schedule a repair</a></div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="row mb-2 gy-2">
        <div class="col-sm-12 col-md-6 d-flex">
          <div id="tickets-table_length" class="me-2 dataTables_length">
            <label class="d-inline-flex mb-0 align-items-center">
              <b-form-select class="form-select" v-model="paginate.perPage" :options="pageOptions"></b-form-select>
            </label>
          </div>
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="ri ri-filter-3-fill"></i>
            </a>
            <ul class="dropdown-menu">
              <li><a @click="selectAllRows()" class="dropdown-item" href="javascript:void(0)"><i class="ri" :class="isSelectAll? 'ri-checkbox-blank-circle-line' : ' ri-checkbox-circle-line'" ></i> {{isSelectAll? 'DeSelect all' : 'Select all' }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-inline text-sm-end">
            <div id="tickets-table_filter" class="dataTables_filter text-md-end">
              <label class="d-inline-flex mb-0 align-items-center">
                <b-form-input v-model="searchQuery" type="search" placeholder="Search id..." class="form-control"></b-form-input>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-5">
        <div class="card-body">
          <div class="table-responsive mb-0">
            <b-table striped hover table-class="table table-centered w-100" thead-tr-class=""
               ref="selectableTable" :items="paginatedItems"
               :fields="columns" responsive="sm">
                <template v-slot:cell(check)="data">
                  <div class="form-check text-center m-auto">
                    <input v-model="selected" type="checkbox" :value="data.item.id" class="form-check-input" :id="`tableSRadio${data.item.id}`" />
                    <label class="form-check-label" :for="`tableSRadio${data.item.id}`"></label>
                  </div>
                </template>
                <template v-slot:cell(id)="data">
                  <div style="min-width:6rem;">
                    {{data.item.friendly_id}}</div>
                </template>
                <template v-slot:cell(total_cost)="data">
                  <span> ${{$filters.money_format(data.item.total_cost)}} </span>
                </template>
                <template v-slot:cell(schedule_date)="data">
                  <div style="min-width:6rem;"> {{$filters.date(data.item.schedule_date)}} </div>
                </template>
                <template v-slot:cell(window_start)="data">
                  <div style="min-width:6rem;"> {{$filters.format_time(data.item.window_start)}} </div>
                </template>
                <template v-slot:cell(status)="data">
                  <div><span v-html="statusBadge(data.item.status)"> </span></div>
                </template>
                <template v-slot:cell(action)="data">
                  <router-link :to="`/appointments/${data.item.id}`" class="">
                    view</router-link>
                </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded">
                  <b-pagination v-model="paginate.currentPage" :limit="paginate.limit" 
                  :total-rows="searchedItems.length" :per-page="paginate.perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "appointment-list",
  data() {
    return {
      paginate:{
        currentPage: 1,
        perPage: 10,
        limit:3
      },
      pageOptions: [10, 25, 50, 100],
      searchQuery: '',
      selected: [],
      isSelectAll: false,
      columns: [
          {
            key: "check",
            label: ""
          },
          {
            key: "id",
            label: "ID",
          },
          {
            key: "total_cost",
            label: "Total Cost",
          },
          {
            key: "schedule_date",
            label: "Schedule Date",
          },
          {
            key: "window_start",
            label: "Time",
          },
          {
            key: "status",
            label: "Status",
          },
          "action"
      ],
    }
  },
  computed: {
    appointments(){
      return this.$store.state.appointments
    },
    searchedItems(){
      return this.appointments.filter(item => this.searchQuery ? item.id == this.searchQuery : true )
    },
    paginatedItems(){
        return this.searchedItems.slice(
          this.paginateStart,
          this.paginateEnd
        );
    },
    paginateStart(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage;
    },
    paginateEnd(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage + this.paginate.perPage;
    },
  },
  methods: {
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.appointments.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    fetchAppointments() {
      this.$store.dispatch("fetchAppointments")
    },
  },
  created(){
    this.fetchAppointments()
  },
}

</script>

